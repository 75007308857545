document.addEventListener("DOMContentLoaded", function () {
  function diseaseTab() {
    const btns = document.querySelectorAll(".disease-tab-item");
    const texts = document.querySelectorAll(".disease-list");

    // Ініціалізація - активувати першу вкладку та відповідний контент
    if (btns.length > 0 && texts.length > 0) {
      btns[0].classList.add("active");
      texts[0].classList.add("active");
    }

    btns.forEach((btn, index) => {
      btn.addEventListener("click", () => {
        // Зняти активний клас з усіх вкладок та контенту
        btns.forEach((button) => button.classList.remove("active"));
        texts.forEach((text) => text.classList.remove("active"));

        // Додати активний клас до натиснутої вкладки та відповідного контенту
        btn.classList.add("active");
        if (texts[index]) {
          texts[index].classList.add("active");
        }
      });
    });
  }

  diseaseTab();
});
