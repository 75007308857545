document.querySelectorAll(".accordeon").forEach((e) => {
  let t = e.querySelector(".accordeon__content");
  t.style.setProperty(
    "--_transition-speed",
    1e3 *
      (
        t.scrollHeight /
        getComputedStyle(t).getPropertyValue("--_pixelsPerSeccond")
      ).toFixed(3) +
      "ms"
  ),
    e.querySelector(".open-button")?.addEventListener("click", () => {
      e.classList.toggle("show"),
        t &&
          ("0px" === getComputedStyle(t).getPropertyValue("--_content-height")
            ? t.style.setProperty("--_content-height", t.scrollHeight + "px")
            : t.style.setProperty("--_content-height", "0px"));
    });
});
