// import 'intl-tel-input/build/css/intlTelInput.min.css';

import "./utils";

import "./swiper";
import "./popup";
import "./accordeon";
import "./formSubmit";
import "./mask";
import "./formMask";
import "./diseaseTab";
import "./closePopap";

import "../css/main.scss";
