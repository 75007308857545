export function clickOn(element, callback) {
  list[element] = callback;
}
document.addEventListener("click", (event) => {
  Object.keys(list).forEach((selector) => {
    if (event.target.matches(selector)) {
      list[selector](event);
    }
  });
});
const list = {
  "popup-open": (e) => {
    document.querySelector(e.target.dataset.target).classList.add("active");
  },
};
