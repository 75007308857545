import Swiper from "swiper/bundle";
import "swiper/css/bundle";

function initializeSwiper() {
  let swiper;

  function initSwiper() {
    if (swiper) {
      swiper.destroy(true, true);
    }

    swiper = new Swiper(".reviews-swiper", {
      slidesPerView: 1,
      spaceBetween: 20,
      autoHeight: true,
      breakpoints: {
        768: {
          spaceBetween: 30,
          slidesPerView: 2,
        },
      },
      navigation: {
        nextEl: window.innerWidth <= 992 ? ".mobile-next" : ".desktop-next",
        prevEl: window.innerWidth <= 992 ? ".mobile-prev" : ".desktop-prev",
      },
    });
  }

  // Ініціалізація Swiper при завантаженні сторінки
  document.addEventListener("DOMContentLoaded", initSwiper);

  // Оновлення Swiper при зміні розміру вікна
  window.addEventListener("resize", initSwiper);
}

// Виклик функції для ініціалізації Swiper
initializeSwiper();

const phlebologySwiper = new Swiper(".phlebology-swiper", {
  slidesPerView: 1,
  spaceBetween: 20,
  autoHeight: true,
  breakpoints: {
    580: {
      spaceBetween: 30,
      slidesPerView: 2,
    },
    992: {
      spaceBetween: 30,
      slidesPerView: 3,
    },
    1200: {
      spaceBetween: 30,
      slidesPerView: 4,
    },
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
});
