function closePopaps() {
  const closeBtn = document.querySelectorAll(".close-button");
  const formsSuccess = document.querySelectorAll(".form-success");
  closeBtn.forEach((btn) => {
    btn.addEventListener("click", () => {
      formsSuccess.forEach((form) => {
        form.style = "display: none";
      });
    });
  });
}
document.addEventListener("DOMContentLoaded", closePopaps);
