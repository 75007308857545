function formSubmit() {
  const forms = document.querySelectorAll(".telegramForm");
  const formsSuccess = document.querySelectorAll(".form-success ");

  const telegramBotToken = "7033441182:AAFttWYYs0MZRPBWm8oZdIfdt0re-uX3WVA";
  const telegramChatId = "-1002179714064";

  forms.forEach((form) => {
    form.addEventListener("submit", function (event) {
      event.preventDefault();

      const formData = new FormData(form);
      const name = formData.get("name");
      const phone = formData.get("phone");

      const message = `Ім'я: ${name}\nНомер телефону: ${phone}`;

      fetch(`https://api.telegram.org/bot${telegramBotToken}/sendMessage`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          chat_id: telegramChatId,
          text: message,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.ok) {
            formsSuccess.forEach((form) => {
              form.style = "display:flex";
            });
            form.reset();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    });
  });
}

document.addEventListener("DOMContentLoaded", formSubmit);
